<template>
  <div v-if="wizardData">
    <Desktop :gameJamData="wizardData" />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Desktop from "@/components/CreateGameJam/Desktop.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "CreateGameJam",
  components: { Desktop },
  data() {
    return {
      wizardData: null,
    };
  },
  created() {
    if (this.$route.params.game_jam_id != "-1") {
      ApiService.get(apiResource.gameJams.getDetails, {
        game_jam_id: this.$route.params.game_jam_id,
      })
        .then((response) => {
          this.wizardData = response.data.data;
        })
        .catch((error) => {
          if (error.response.data.msg === "There is no game jam available") {
            toast.error(
              "There is no game jam available. Redirecting you to the dashboard.",
              { timeout: 2000 }
            );
            setTimeout(() => {
              this.$router.replace({
                name: "Dashboard",
              });
            }, 2000);
          }
        });
    } else {
      this.wizardData = {
        game_jam_id: "-1",
        step1: null,
        step2: null,
        step3: null,
        step4: null,
      };
    }
  },
  mounted() {},
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
